@import '../../shared';

.tag {
  padding: 4px 12px;
  color: $black;
  font-size: 14px;
  text-align: center;

  &--done {
    color: $white;
    background-color: $status-green;
  }

  &--not-required {
    background-color: $status-grey;
  }

  &--wip {
    background-color: $status-light-green;
  }

  &--open {
    background-color: $status-yellow;
  }

  &--error {
    background-color: $status-red;
  }

  &--primary {
    background-color: $red;
    color: $white;
  }
}
