@import "../../shared";

.form-checkbox {
  position: relative;
  width: 1.625rem;
  height: 1.625rem;

  input {
    @include input-reset;

    opacity: 0;
    position: absolute;
    height: 1.625rem;
    width: 1.625rem;
    margin: 0;
    z-index: 1;
  }

  input + label {
    position: relative;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 1.5rem;
      height: 1.5rem;
      border: 1px solid $form-border-grey;
      background: none;
      transition: background-color 200ms, border-color 200ms;
    }
  }

  input:checked + label {
    &::before {
      border-color: $red;
      background-color: $red;
      background-image: url(../../../images/icon-checkbox.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
    }
  }

  input:disabled + label {
    opacity: 0.5;
    pointer-events: none;
  }

  &--minus {
    input:checked + label::before {
      background-image: url(../../../images/icon-minus-white.svg);
      background-size: 80%;
    }
  }
}
