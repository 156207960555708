.link-list {
  &__item {
    background-color: $white;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    margin-bottom: 14px;
    padding: 20px 24px 16px 15px;
    position: relative;
    transform: scale(1);
    will-change: transform, background-color, box-shadow;
    transition: transform $animation-medium, background-color $animation-medium, box-shadow $animation-medium;

    &::before {
      background-color: $red;
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 8px;
    }

    &:hover {
      background-color: $light-grey;
      transform: scale(1.01);
      box-shadow: 2px 8px 8px rgba(0, 0, 0, 0.25);
    }
  }

  &__link {
    &::before {
      content: "";
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &--width-medium {
    width: 50%;
  }

  &--centered {
    margin: 0 auto;
  }
}
