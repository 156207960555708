.hide-on-mobile {
  display: none;

  @include breakpoint(lg) {
    display: block;
  }
}

.hide-on-desktop {
  display: block;

  @include breakpoint(lg) {
    display: none;
  }
}
