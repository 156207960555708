@import '../../shared';

.page-content {
  margin: 2rem auto;
  max-width: $page-content-width;
  min-height: 50vh;
  padding-left: $page-content-spacing-x;
  padding-right: $page-content-spacing-x;
  position: relative;
  overflow: hidden;

  @include breakpoint(lg) {
    margin: 3rem auto;
  }

  & > * + * {
    margin-top: 2rem;
  }

  &__headline {
    @extend .headline;
    @extend .headline--level-1;
  }

  &--centered {
    text-align: center;
  }

  &--small {
    max-width: $page-content-width * 0.5;
  }
}
