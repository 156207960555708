@import "../../shared";

.tabs-content {
  margin-bottom: 2rem;
  margin-top: 1.5rem;
  min-height: 300px;

  @include breakpoint(md) {
    display: block;
    padding: 1.5rem;
    margin-top: 0;
    margin-bottom: 0;
  }

  & > * + * {
    margin-top: 1.5rem;

    @include breakpoint(md) {
      margin-top: 3rem;
    }
  }

  &__intro {
    margin-bottom: 1.5rem;
  }

  .rdt_Table {
    margin-right: 0;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0.25rem;
  }
}

.tabs-wrapper {
  @include breakpoint(md) {
    overflow-x: auto;
    border: 2px solid $border-grey;
    border-top: none;
  }
}

