@import '../../1_settings/colors';
@import '../../1_settings/fonts';
@import '../../2_tools/resets/link-reset';

.link {
  @include link-reset();

  font-family: $font;
  font-weight: bold;
  font-size: inherit;
  line-height: 1.45;
  color: $red;
  transition: color 0.2s;
  will-change: color;

  &:hover {
    color: rgba($red, 0.6);
  }

  &--with-chevron {
    font-weight: normal;
    position: relative;
    font-size: $font-size-m;
    line-height: $font-size-m;
    padding-right: 1.5rem;
    white-space: nowrap;

    &::after {
      position: absolute;
      content: "";
      height: $font-size-m;
      width:  1rem;
      right: 0;
      top: 3px;
      background-repeat: no-repeat;
      background-image: url("../../../images/chevron-right.svg");

      &:hover {
        color: rgba($red, 0.6);
      }
    }
  }

  &--align-top-right {
    @include breakpoint(lg) {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}
