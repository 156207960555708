@import '../../1_settings/colors';
@import '../../1_settings/fonts';

.form-switch {
  $component: &;

  cursor: pointer;
  display: flex;
  font-family: $font;
  font-weight: normal;
  width: auto !important;
  height: auto !important;

  &__element {
    margin-right: 10px;
  }

  &__switch {
    position: relative;
    cursor: pointer;
    display: inline-block;
    width: 40px;
    height: 20px;
    background-color: rgba($black, 0.4);
    border-radius: 20px;
    transition: background-color 0.3s ease;
    will-change: background-color;

    &::after {
      content: '';
      position: absolute;
      width: 18px;
      height: 18px;
      border-radius:50%;
      background-color: white;
      top: 1px;
      left: 1px;
      transition: left 0.3s ease;
      will-change: left;
    }

    &--disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  &__checkbox {
    display: none;

    &:checked + #{$component}__switch {
      background-color: $red;

      &::after {
        left: 21px;
      }
    }
  }
}
