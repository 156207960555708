// Input Element zurücksetzen.
//
// input.example {
//   @include input-reset;
// }
@mixin input-reset {
  background: none;
  padding: 0;
  border: none;
  border-radius: 0;
  font: inherit;
  -webkit-appearance: none !important;
}
