.page-sidebar {
  position: sticky;
  top: 0;
  bottom: 0;
  background-color: $light-grey;
  flex-shrink: 0;
  width: 240px;
  height: 100vh;
  font-size: 14px;

  @include breakpoint(md) {
    width: 320px;
    font-size: 16px;
  }

  &__nav {
    padding: 0 1rem;
  }

  &__nav-item {
    padding: 0.75rem 0;
    cursor: pointer;
    transition: 0.2s ease;

    &--active {
      color: $red;
    }
  }

  &__logo {
    display: block;
    margin: 2rem 1rem;
  }

  &__sign-out {
    position: absolute;
    bottom: 2rem;
    left: 1rem;
    color: $red;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
