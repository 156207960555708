@import '../../shared';

.loan-details {
  border: 2px solid $border-grey;
  padding: 1rem;
  width: auto;

  @include breakpoint(md) {
    padding: 1.5rem;
  }

  .pie-chart {
    margin-left: auto;
    margin-right: auto;
  }

  &__content {
    display: flex;
    width: auto;
    flex-wrap: wrap;
    align-items: center;

    & > * + * {
      margin: auto;
      padding: 1rem;

      @include breakpoint(sm) {
        padding: 1.5rem;
      }

      @include breakpoint(md) {
        padding: 2rem;
      }
    }
  }

  &__list {
    flex-shrink: 0;

    & > * + * {
      margin-top: 0.625rem;
    }
  }

  &__item {
    line-height: 1.375rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 2rem;
    align-items: end;

    @include breakpoint(md) {
      grid-template-columns: 1fr auto;
    }
  }

  &__item-with-dot {
    $square-size: 0.75rem;

    padding-left: $square-size + 0.75rem;
    line-height: 1.375rem;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      border-radius: 50%;
      left: 0;
      top: 5px;
      width: $square-size;
      height: $square-size;
    }

    &--delta {
      &::before {
        background-color: $pie-chart-delta;
      }
    }

    &--available {
      &::before {
        background-color: $green;
      }
    }

    &--paid {
      &::before {
        background-color: $dark-green;
      }
    }
  }

  &__number {
    @include breakpoint(md) {
      font-weight: bold;
    }
  }
}
