@import '../../shared';

.key-value-box {
  padding: 1rem 1.5rem;
  background-color: $light-grey;
  width: auto;
  margin: 0;

  @include breakpoint(sm) {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-row-gap: 0.5rem;
    grid-column-gap: 2rem;
  }

  dd {
    margin-inline-start: 0;
  }

  &--one-column {
    grid-template-columns: 1fr;
  }

  &__headline {
    font-weight: bold;
    margin-bottom: 1rem;
  }

  &-wrapper {
    background-color: $light-grey;
    padding: 1rem 1.5rem;

    .key-value-box {
      padding: 0;
    }
  }
}
