$white: #ffffff;
$light-black: #666666;
$black: #000000;
$grey: #878787;
$light-grey: #f1f1f1;
$border-grey: #F0F0F0;
$form-border-grey: #C6C6C6;
$red: #f84914;
$light-red: #fc7e51;
$dark-red: #e20a16;
$green: #94C23C;
$dark-green: #65882A;
$lightest-red: #fee2e2;
$orange: #e29f3e;

$pie-chart-delta: #e3e3e3;
$summary-label: $grey;
$hint-text: $grey;
$errorBackgroundColor: $lightest-red;
$success: $green;
$successMessage: $dark-green;
$error: $dark-red;
$warning: $orange;
$info: $black;

$button-skin-hover: #ffad93;
$button-skin: #ffccbd;
$alert: #F84914;
$skin: #FF9C7D;
$brown: #942200;

$status-green: #65882A;
$status-light-green: #94C23C;
$status-grey: #AAAAAA;
$status-yellow: #F0AA00;
$status-red: #E20A16;
