@import '../../1_settings/colors';
@import '../../1_settings/fonts';

:root {
  .headline {
    font-family: $font;
    font-weight: bold;
    line-height: 1.25em;
    display: block;
    hyphens: auto;
    color: $black;

    &--level-1 {
      font-size: $font-size-l;
      color: $black;

      @include breakpoint(md) {
        font-size: $font-size-xxxxl-smaller;
      }
    }

    &--level-2 {
      font-size: $font-size-m;

      @include breakpoint(md) {
        font-size: $font-size-xl;
      }
    }

    &--level-3 {
      font-size: $font-size-ml;
    }

    &--centered {
      text-align: center;
    }

    &--large-top-space {
      margin-top: 1.5em;
    }

    &--no-space {
      margin: 0;
    }

    &--red {
      color: $alert;
    }

    &--space-bottom {
      margin-bottom: 1rem;

      @include breakpoint(md) {
        margin-bottom: 2rem;
      }
    }
  }
}
