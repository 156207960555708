.seperated-by-lines {
  & > * + * {
    border-top: 1px solid $light-grey;
    margin-top: 3rem;
    padding-top: 2rem;

    @include breakpoint(md) {
      margin-top: 4rem;
      padding-top: 4rem;
    }

    @include breakpoint(lg) {
      margin-top: 5rem;
      padding-top: 5rem;
    }
  }
}