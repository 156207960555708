@import "../../shared";

.toast-alert {
  font-size: 1.125rem;
  padding: 1rem 0.625rem;
  border-radius: 0;
  max-width: 90% !important;

  > :not([role="status"]) {
    display: none;
  }

  &__content {
    display: flex;
    align-items: center;
    color: $white;
    line-height: 1.5;
  }

  &__close {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    height: 1.25rem;
    margin-left: auto;
    padding-left: 2rem;

    img,
    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
}
