$breakpoints: (
        xs: 320px,
        sm: 576px,
        md: 768px,
        navigation: 1020px,
        lg: 992px,
        xl: 1200px,
        xxl: 1440px,
);

$border-radius: (
        normal: 6px
);

$z-index: (
        underneath: -1,
        body: 0,
        sidebar: 100,
        overlay: 6,
        modal: 8,
);


$spacing-vertical-m: 1.5rem; // 24px
$spacing-vertical-s: 1.25rem; // 20px
$spacing-vertical-xs: 1rem; // 16px

$header-logo-height: 3.125rem; // 50px
$header-small-inner-spacing-bottom: $spacing-vertical-xs;
$header-small-inner-spacing-top: $spacing-vertical-xs;
$header-spacing-bottom: $spacing-vertical-s;
$header-large-inner-spacing-bottom: $spacing-vertical-s;

$header-width: 1340px;
$page-content-width: 1240px;
$max-width: 1170px;

$sidebar-width: 250px;
$sidebar-width-mobile: 40px;
$page-content-spacing-x: 1.5rem;

