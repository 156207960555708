@font-face {
  font-display: swap;
  font-family: "wwtype";
  src: local("../../fonts/WWTypeWeb-Regular.eot");
  src: url("../../fonts/WWTypeWeb-Regular.eot?#iefix") format("embedded-opentype"),
  url("../../fonts/WWTypeWeb-Regular.woff2") format("woff2"),
  url("../../fonts/WWTypeWeb-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: "wwtype";
  src: local("../../fonts/WWTypeWeb-Bold.eot");
  src: url("../../fonts/WWTypeWeb-Bold.eot?#iefix") format("embedded-opentype"),
  url("../../fonts/WWTypeWeb-Bold.woff2") format("woff2"),
  url("../../fonts/WWTypeWeb-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

$font: "wwtype", arial, helvetica, sans-serif;


$font-size-xxxxl: 7.5rem; // 120px
$font-size-xxxl: 2.5rem; //  40px
$font-size-xxxxl-smaller: 2rem; //  32px
$font-size-xxl: 1.75rem; //  28px
$font-size-xl: 1.5rem; //  24px
$font-size-l: 1.375rem; //  22px
$font-size-ml: 1.25rem; //  20px
$font-size-m: 1.125rem; //  18px
$font-size-s: 1rem; //  16px
$font-size-xs: 0.875rem; //  14px
