@import '../../1_settings/colors';
@import '../../1_settings/fonts';

.hint-text {
  color: $hint-text;
  display: flex;
  font-family: $font;
  font-size: inherit;
  font-weight: normal;
  justify-content: space-between;

  &__icon {
    width: 48px;
    height: auto;
    margin-right: 20px;
  }

  &--highlighted {
    background-color: $red;
    color: $white;
    font-size: 1.125rem;
    margin-top: 1rem;
    padding: 10px;
  }
}
