@import "../../1_settings/layout";
@import "../../2_tools/general/breakpoints";

.form-row {
  $component: &;
  margin-bottom: 1.875rem;
  position: relative;

  &--search {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;

    @include breakpoint(sm) {
      flex-direction: row;
    }

    .button {
      margin: 0;
    }
  }

  &--search-input-row {
    width: 100%;
  }

  &--flex-wrap {
    flex-wrap: wrap;
  }

  &--flex {
    display: flex;
    flex-direction: column;

    @include breakpoint(sm) {
      flex-direction: row;
    }

    #{$component}:first-child,
    #{$component}:last-child {
      margin-right: 0;
    }

    @include breakpoint(sm) {
      #{$component}:first-child {
        margin-right: 15px;
      }

      #{$component}:last-child {
        margin-left: 15px;
      }
    }


    #{$component}--30 {
      width: 100%;

      @include breakpoint(sm) {
        width: 30%;
      }
    }

    #{$component}--70 {
      width: 100%;

      @include breakpoint(sm) {
        width: 70%;
      }
    }

    #{$component}--50 {
      width: 100%;

      @include breakpoint(sm) {
        width: 50%;
      }
    }

    #{$component}--full-width {
      width: 100%;
    }

    #{$component} #{$component} {
      margin: 0;
      width: 100%;
    }
  }

  &--box-shadow {
    box-shadow: 0 4px 9px rgba(0, 0, 0, 0.25);
  }

  &--flex-wrap {
    flex-wrap: wrap;
  }

  &--horizontal {
    display: flex;
    flex-wrap: nowrap;
  }

  &--space-between {
    justify-content: space-between;
  }

  &--flex-start {
    justify-content: flex-start;
  }

  &--align-flex-end {
    align-items: flex-end;
  }

  &--margin-bottom {
    margin-bottom: 2.5rem;
  }

  &--hidden {
    margin-bottom: 0.625rem;
  }

  &--with-textarea {
    display: flex;
    flex-direction: column;
    height: 300px;

    .form-element--textarea {
      flex: 1 auto;
    }
  }

  &--info {
    font-size: 14px;
  }
}
