@use "sass:math";

@import "../../1_settings/colors";

$spinner-width: 1.5rem;
$spinner-width-lg: 2.5rem;
$spinner-border-width: math.div($spinner-width, 6);
$spinner-border-width-lg: math.div($spinner-width-lg, 6);

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  $spinner: &;

  position: relative;
  background-color: inherit;
  border-radius: 10rem;
  overflow: hidden;
  padding: 2px;

  &__element {
    width: $spinner-width;
    height: $spinner-width;
    background-color: inherit;
    background-image: radial-gradient($red, $red);
    animation: spinner 0.75s linear infinite;
    transform-origin: center;
    border-radius: 50%;

    &::before {
      content: "";
      position: absolute;
      top: $spinner-border-width;
      left: $spinner-border-width;
      right: $spinner-border-width;
      bottom: $spinner-border-width;
      background-color: inherit;
      border-radius: 50%;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      width: 50%;
      height: 50%;
      background-color: inherit;
    }
  }

  &--large {
    #{$spinner}__element {
      width: $spinner-width-lg;
      height: $spinner-width-lg;

      &::before {
        top: $spinner-border-width-lg;
        left: $spinner-border-width-lg;
        right: $spinner-border-width-lg;
        bottom: $spinner-border-width-lg;
      }
    }
  }

  &--white #{$spinner}__element {
    background-image: radial-gradient($white, $white);
  }
}
