@import '../../shared';
@import '../general/buttons';

.file-drop-upload {
  &__dropzone {
    @include border-radius(normal);

    align-items: center;
    background-color: $white;
    border: 2px dashed $grey;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 200px;
    transition: 0.2s ease;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
  }

  &__label {
    margin-left: 1rem;
  }

  &__button {
    margin-top: 2rem;

    @include breakpoint(sm) {
      margin-top: 0;
      margin-left: 2rem;
    }
  }

  &.drag-active {
    .file-drop-upload__dropzone {
      border-color: $red;
    }
  }
}
